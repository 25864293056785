import axios from "axios";

import { base_api } from "../../services/api";

export const setCategoryProduct = (idCategory) => {
  return async (dispatch) => {
    dispatch({ type: "SET_CATEGORY_PRODUCT", payload: idCategory });
  };
};

export const getListProducts = (paramOption) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_PRODUCTS_START" });
      const axiosConfigProducts = {
        headers: {
          Authorization: `Bearer ${paramOption.token}`,
        },
        params: {
          limit: paramOption.limit,
          page: paramOption.page,
          order: paramOption.order,
          kategori_produk: paramOption.category,
          nama_produk: paramOption.productName,
          provinsi: paramOption.province,
          kota: paramOption.city,
          pdn: paramOption.pdn,
          tkdn: paramOption.tkdn,
          nilai_tkdn: paramOption.tkdnValue,
          pkp: paramOption.pkp,
          id_reseller: paramOption.resellerId,
          termahal: paramOption.sortHighestPrice,
          terbaru: paramOption.sortNewest,
        },
      };
      const responseProducts = await axios.get(
        `${base_api}lkpp/products/get-list-products/`,
        axiosConfigProducts
      );

      const axiosConfigProvinces = {
        headers: {
          Authorization: `Bearer ${paramOption.token}`,
        },
      };
      const responseProvinces = await axios.get(
        `${base_api}b2g/products/get-provinces`,
        axiosConfigProvinces
      );

      dispatch({
        type: "GET_PRODUCTS_SUCCESS",
        payload: {
          dataProducts: responseProducts.data.data,
          dataProvinces: responseProvinces.data.data,
        },
      });
    } catch (error) {
      dispatch({
        type: "GET_PRODUCTS_FAILED",
        payload: error.message,
      });
      dispatch({
        type: "VALIDATE_ERROR",
        payload: error.message,
      });
    }
  };
};

export const getListCities = (token, provinceId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_CITIES_START" });
      const axiosConfigCities = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const responseCity = await axios.get(
        `${base_api}b2g/products/get-cities/${provinceId}`,
        axiosConfigCities
      );
      dispatch({ type: "GET_CITIES_SUCCESS", payload: responseCity.data.data });
    } catch (error) {
      dispatch({
        type: "GET_CITIES_FAILED",
        payload: error.message,
      });
      dispatch({
        type: "VALIDATE_ERROR",
        payload: error.message,
      });
    }
  };
};

export const getListFilterProducts = (paramOption) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_FILTER_START" });

      const axiosConfigProducts = {
        headers: {
          Authorization: `Bearer ${paramOption.token}`,
        },
        params: {
          limit: paramOption.limit,
          page: paramOption.page,
          order: paramOption.order,
          kategori_produk: paramOption.category,
          nama_produk: paramOption.productName,
          provinsi: paramOption.province,
          kota: paramOption.city,
          pdn: paramOption.pdn,
          tkdn: paramOption.tkdn,
          nilai_tkdn: paramOption.tkdnValue,
          pkp: paramOption.pkp,
          id_reseller: paramOption.resellerId,
          termahal: paramOption.sortHighestPrice,
          terbaru: paramOption.sortNewest,
        },
      };
      const responseFilterProducts = await axios.get(
        `${base_api}lkpp/products/get-list-products/`,
        axiosConfigProducts
      );
      dispatch({
        type: "GET_FILTER_SUCCESS",
        payload: responseFilterProducts.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_FILTER_FAILED",
        payload: error.message,
      });
      dispatch({
        type: "VALIDATE_ERROR",
        payload: error.message,
      });
    }
  };
};

export const getListSearchResults = (paramProduct, paramStore) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_SEARCH_START" });

      const axiosConfigSearchProducts = {
        headers: {
          Authorization: `Bearer ${paramProduct.token}`,
        },
        params: {
          limit: paramProduct.limit,
          page: paramProduct.page,
          order: paramProduct.order,
          kategori_produk: paramProduct.category,
          nama_produk: paramProduct.productName,
          provinsi: paramProduct.province,
          kota: paramProduct.city,
          pdn: paramProduct.pdn,
          tkdn: paramProduct.tkdn,
          nilai_tkdn: paramProduct.tkdnValue,
          pkp: paramProduct.pkp,
          id_reseller: paramProduct.resellerId,
          termahal: paramProduct.sortHighestPrice,
          terbaru: paramProduct.sortNewest,
        },
      };
      const responseSearchProducts = await axios.get(
        `${base_api}lkpp/products/get-list-products/`,
        axiosConfigSearchProducts
      );

      const axiosConfigSearchStores = {
        headers: {
          Authorization: `Bearer ${paramStore.token}`,
        },
        params: {
          limit: paramStore.limit,
          page: paramStore.page,
          nama_reseller: paramStore.resellerName,
          provinsi_id: paramStore.province,
          kota_id: paramStore.city,
          pkp: paramStore.pkp,
        },
      };
      const responseSearchStores = await axios.get(
        `${base_api}b2g/products/get-toko/`,
        axiosConfigSearchStores
      );

      dispatch({
        type: "GET_SEARCH_SUCCESS",
        payload: {
          dataProducts: responseSearchProducts.data.data,
          dataStores: responseSearchStores.data.data,
        },
      });
    } catch (error) {
      dispatch({
        type: "GET_SEARCH_FAILED",
        payload: error.message,
      });
      dispatch({
        type: "VALIDATE_ERROR",
        payload: error.message,
      });
    }
  };
};

export const getDetailProduct = (token, idProduk) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_DETAIL_PRODUCT_START" });
      const axiosConfigGetDetailProduct = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const responseGetDetailProduct = await axios.get(
        `${base_api}lkpp/products/get/${idProduk}`,
        axiosConfigGetDetailProduct
      );
      dispatch({
        type: "GET_DETAIL_PRODUCT_SUCCESS",
        payload: responseGetDetailProduct.data.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_DETAIL_PRODUCT_FAILED",
        payload: error.message,
      });
      dispatch({
        type: "VALIDATE_ERROR",
        payload: error.message,
      });
    }
  };
};

export const getDetailToko = (paramOption) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_DETAIL_TOKO_START" });
      const axiosConfigDetailToko = {
        headers: {
          Authorization: `Bearer ${paramOption.token}`,
        },
        params: {
          limit: paramOption.limit,
          page: paramOption.page,
          kategori_produk: paramOption.kategori_produk,
          nama_produk: paramOption.nama_produk,
          id_reseller: paramOption.id_reseller,
          terbaru: paramOption.terbaru,
          termahal: paramOption.termahal,
        },
      };
      const responseDetailToko = await axios.get(
        `${base_api}lkpp/products/get-list-products/`,
        axiosConfigDetailToko
      );
      dispatch({
        type: "GET_DETAIL_TOKO_SUCCESS",
        payload: {
          dataDetailToko: responseDetailToko.data.data,
        },
      });
    } catch (error) {
      dispatch({
        type: "GET_DETAIL_TOKO_FAILED",
        payload: error.message,
      });
    }
  };
};

export const getUlasan = (paramOption) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_ULASAN_START" });
      const axiosConfigUlasan = {
        headers: {
          Authorization: `Bearer ${paramOption.token}`,
        },
        params: {
          limit: paramOption.limit,
          page: paramOption.page,
          idProduk: paramOption.idProduk,
          rating: paramOption.rating,
        },
      };
      const responseUlasan = await axios.get(
        `${base_api}lkpp/review`,
        axiosConfigUlasan
      );
      dispatch({
        type: "GET_ULASAN_SUCCESS",
        payload: {
          dataUlasan: responseUlasan.data.data,
        },
      });
    } catch (error) {
      dispatch({
        type: "GET_ULASAN_FAILED",
        payload: error.message,
      });
    }
  };
};

export const getInfoToko = (token, idReseller) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_TOKO_START" });
      const axiosGetInfoToko = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${base_api}lkpp/reseller?idReseller=${idReseller}`,
        axiosGetInfoToko
      );
      console.log("Response Info Toko:", response.data.data);
      dispatch({
        type: "GET_TOKO_SUCCESS",
        payload: response.data.data,
      });
    } catch (error) {
      console.error("Error fetching info toko:", error);
      dispatch({
        type: "GET_TOKO_FAILED",
        payload: error.message,
      });
    }
  };
};
