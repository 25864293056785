import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  getRenego,
  submitFinalRenego,
  submitProductRenego,
} from "../../redux/actions/transactionsAction";
import {
  Button,
  Card,
  Divider,
  Flex,
  Image,
  InputNumber,
  Modal,
  notification,
} from "antd";
import { CurrencyFormatter, ThousandSeparatorDot } from "../../utilities/utils";
import { bindActionCreators } from "redux";
import imageUrl from "../../assets/img/no_image_found.png";

const ProductItem = ({ isLastElement, data, onPressButton }) => {
  const { nama_produk, harga, harga_nego, qty, gambar } = data;

  return (
    <div>
      <Flex gap={"large"} className="basis-2/5" justify="space-between">
        <Flex gap={"middle"} align="center">
          <Image src={gambar || imageUrl} className="max-w-16 min-w-7" />
          <div>
            <p className="font-bold max-w-96 mb-2">{nama_produk}</p>
            <Button
              type="primary"
              onClick={() => {
                onPressButton(data);
              }}
            >
              Ajukan Nego
            </Button>
          </div>
        </Flex>

        <Flex
          gap={"middle"}
          justify="space-between"
          align="center"
          className="basis-3/5"
        >
          {harga_nego ? (
            <Flex vertical>
              <p className="line-through">{CurrencyFormatter(harga)}</p>
              <p className="font-bold">{CurrencyFormatter(harga_nego)}</p>
            </Flex>
          ) : (
            <Flex vertical>
              <p>{CurrencyFormatter(harga)}</p>
            </Flex>
          )}

          <p>x {ThousandSeparatorDot(qty)}</p>
          {harga_nego ? (
            <Flex vertical>
              <p className="line-through">{CurrencyFormatter(harga * qty)}</p>
              <p className="font-bold text-red-500">
                {CurrencyFormatter(harga_nego * qty)}
              </p>
            </Flex>
          ) : (
            <p>{CurrencyFormatter(harga * qty)}</p>
          )}
        </Flex>
      </Flex>

      {!isLastElement && <Divider className="border-[#A1A1AA]" />}
    </div>
  );
};

const Renegotiation = (props) => {
  const { idSessionNego } = useParams();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfimModalOpen, setIsConfimModalOpen] = useState(false);
  const [isModalSubmitNego, setIsModalSubmitNego] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [priceNego, setPriceNego] = useState(0);

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type) => {
    api[type]({
      message: "Peringatan",
      description: "Harga nego tidak boleh kosong.",
    });
  };

  useEffect(() => {
    props.getRenego(props.accessToken, idSessionNego);
    window.scrollTo(0, 0);
  }, []);

  const submitNegoProduct = async () => {
    await props.submitProductRenego(
      props.accessToken,
      priceNego,
      popupData.id_cart
    );
    await props.getRenego(props.accessToken, idSessionNego);

    setIsConfimModalOpen(false);
    setIsModalOpen(false);
  };

  const submitNegoFinal = async () => {
    await props.submitFinalRenego(props.accessToken, idSessionNego);
  };

  useEffect(() => {
    if (props.isSuccessSubmitFinal) {
      let session = props.detailNego[0].session;
      navigate("/transaksi/detail-nego/" + session);
    }
  }, [props.isSuccessSubmitFinal]);

  let totalPrice = 0;
  let totalPriceNego = 0;

  return (
    <div className="mx-4 mt-14">
      {contextHolder}
      <Flex gap="middle" align="start">
        <Card className="basis-4/6 shadow-lg">
          <p className="font-bold text-lg mb-3">CV Mitra Sinar</p>
          {props.listProductRenego?.map((el, i, arr) => {
            totalPrice += el.harga * el.qty;
            totalPriceNego += el.harga_nego
              ? el.harga_nego * el.qty
              : el.harga * el.qty;

            return (
              <ProductItem
                key={i}
                isLastElement={i === arr.length - 1}
                data={el}
                onPressButton={(popupData) => {
                  setPopupData(popupData);
                  setIsModalOpen(true);
                }}
              />
            );
          })}
        </Card>
        <Card className="basis-2/6 shadow-lg">
          <p className="font-bold text-lg mb-3">Detail Harga</p>
          <Flex justify="space-between">
            <p>Harga Asli</p>
            <p>{CurrencyFormatter(totalPrice)}</p>
          </Flex>
          <Flex justify="space-between">
            <p>Harga Nego</p>
            {/* <p className="font-bold">Rp. 1.850.000</p> */}
            <p className="font-bold">{CurrencyFormatter(totalPriceNego)}</p>
          </Flex>
          <Flex justify="space-between" className="mb-2">
            <p className="text-green-600">Selisih</p>
            <p className="text-green-600">
              {CurrencyFormatter(totalPrice - totalPriceNego)}
            </p>
          </Flex>
          <Button
            type="primary"
            style={{ width: "100%" }}
            onClick={() => {
              setIsModalSubmitNego(true);
            }}
          >
            Submit Permintaan Nego
          </Button>
        </Card>
      </Flex>

      <Modal
        open={isModalOpen}
        title={
          <p className="font-bold text-[#208b6a] border-b-2 pb-2">
            Tentukan Harga Nego
          </p>
        }
        onOk={() => {
          setPriceNego(0);
          // setIsModalOpen(false);
          // setIsConfimModalOpen(true);
        }}
        onCancel={() => {
          setPriceNego(0);
          setIsModalOpen(false);
        }}
        footer={[
          <Button
            key="submit"
            onClick={() => {
              if (!priceNego) {
                openNotificationWithIcon("warning");
                return;
              }
              setIsConfimModalOpen(true);
            }}
            type="primary"
            className="w-full font-semibold"
          >
            Kirim Penawaran Harga
          </Button>,
        ]}
      >
        <div className="font-bold">
          <p className="font-bold mb-2">Nama Produk</p>
          <Flex gap={"middle"} align="center">
            <Image src={popupData.gambar || imageUrl} className="max-w-10" />
            <p className=" text-[#208b6a]">{popupData.nama_produk}</p>
          </Flex>
          <Divider className="border-[#D4D4D8] my-3" />
        </div>

        <Flex justify="space-between" className="font-bold">
          <p className="mb-3">Total Harga ([{popupData.qty}] item)</p>
          <p>
            {CurrencyFormatter(
              popupData.qty *
                (popupData.harga_nego ? popupData.harga_nego : popupData.harga)
            )}
          </p>
        </Flex>

        <Flex justify="space-between">
          <p className="font-semibold basis-3/5">
            Masukan Nominal Penawaran Harga
          </p>
          <InputNumber
            min={0}
            max={totalPrice}
            onChange={(num) => {
              setPriceNego(num);
            }}
            value={priceNego}
            prefix="Rp"
            className="basis-2/5"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
            parser={(value) => value?.replace(/\$\s?|(\.*)/g, "")}
            style={{ borderColor: "#208b6a" }}
          />
        </Flex>
      </Modal>

      <Modal
        open={isConfimModalOpen}
        confirmLoading={props.isLoading}
        title={"Konfirmasi Nego"}
        cancelText={"Batal"}
        okText={"Konfirmasi"}
        onOk={submitNegoProduct}
        maskClosable={false}
        onCancel={() => {
          setIsConfimModalOpen(false);
        }}
      >
        <p className="mb-3">
          {" "}
          Apakah anda yakin nego produk <b>{popupData.nama_produk}</b> dengan
          harga <b>{CurrencyFormatter(priceNego)}</b>
        </p>
      </Modal>

      <Modal
        open={isModalSubmitNego}
        confirmLoading={props.isLoading}
        title={"Submit Nego"}
        cancelText={"Batal"}
        okText={"Iya"}
        onOk={submitNegoFinal}
        maskClosable={false}
        onCancel={() => {
          setIsModalSubmitNego(false);
        }}
      >
        <p className="mb-3">
          Apakah anda sudah yakin untuk mengkonfirmasi sesi nego ini?
        </p>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state.auth.resultDecode.accessToken,
  isSuccessSubmitFinal: state.transactions.isSuccessSubmitFinal,
  isLoading: state.transactions.isLoading,
  detailNego: state.transactions.detailNego,
  listProductRenego: state.transactions.listProductRenego,
});

// const mapDispatchToProps = (dispatch) => ({
//   getDetailNego: (token, idSessionNego) =>
//     dispatch(getDetailNego(token, idSessionNego)),
// });
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getRenego,
      submitProductRenego,
      submitFinalRenego,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Renegotiation);
